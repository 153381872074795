<template>
  <section class="container pb-8 ">
    <div class="container">
      <div class="error-bg mb-3 mb-md-8">
        <div class="flex-wrap">
          如果您在正常操作下看到此頁，請聯絡補習班管理員，並提供您的IP<br />
          <a href="https://ifconfig.me/" target="_blank">
            查詢IP位置
          </a>
        </div>
      </div>
      <!-- 返回首頁 -->
      <div class="d-flex justify-content-end">
        <router-link class="btn btn-primary btn-hover-shadow px-6 py-2 py-md-3" to="/">
          返回首頁
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    const temp = new Date();
    const d = new Date(temp.setDate(temp.getDate() - 1));
    document.cookie = `token=;expires=${d}; path=/`;
    document.cookie = `CloudFront-Policy=; expires=${d}; path=/`;
    document.cookie = `CloudFront-Key-Pair-Id=; expires=${d}; path=/`;
    document.cookie = `CloudFront-Signature=; expires=${d}; path=/`;
    this.$setUserAccount();
  },
};
</script>

<style></style>
